import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { OrganizationsService } from '../../organizations/organizations.service';
import { ZenCustomersService } from '../zen-customers.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { UsersService } from '../../users/users.service';

@Component({
	selector: 'app-zenmaster-admin-form',
	templateUrl: './zenmaster-admin-form.component.html',
	styleUrls: ['./zenmaster-admin-form.component.scss']
})
export class ZenmasterAdminFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' view stuff
	id: number;
	zenCustomer: Models.ZenMasterCustomer;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private userSubscription: Subscription;

	randomPassword: string = '';
	showPassword: boolean = false;

	// other stuff

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private authService: AuthService,
		private zenCustomersService: ZenCustomersService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe(authUser => {
				// this.authUser = authUser;
				this.initForm();
			});
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.loading = true;

		this.zenCustomer = this.zenCustomersService.getOne(this.id);
		if (!this.zenCustomer || this.zenCustomer == null || this.zenCustomer.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
		}

		this.theForm = new UntypedFormGroup({
			name: new UntypedFormControl(this.zenCustomer.name, [Validators.required]),
			content_analysis: new UntypedFormControl(this.zenCustomer.content_analysis),
			automation: new UntypedFormControl(this.zenCustomer.automation),
			live_events: new UntypedFormControl(this.zenCustomer.live_events),
			allow_agentz: new UntypedFormControl(this.zenCustomer.allow_agentz),
			allow_non_sso_login: new UntypedFormControl(this.zenCustomer.allow_non_sso_login)
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		this.zenCustomer.name = this.theForm.value.name;
		this.zenCustomer.content_analysis = this.theForm.value.content_analysis;
		this.zenCustomer.automation = this.theForm.value.automation;
		this.zenCustomer.live_events = this.theForm.value.live_events;
		this.zenCustomer.allow_agentz = this.theForm.value.allow_agentz;
		this.zenCustomer.allow_non_sso_login = this.theForm.value.allow_non_sso_login;

		try {
			const updatedZenmaster = await this.zenCustomersService.adminUpdate(this.zenCustomer);
			if (updatedZenmaster) {
				this.router.navigate([AppConstants.urls.zencustomers, updatedZenmaster.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.zencustomers, this.id]);
	}
}
