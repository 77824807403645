import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import AccessControl from 'appshared/access-control';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import PopOverTools from 'appshared/popover-tools';

import TrackRecent from '../../../../helpers/track-recent';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { UsersService } from '../users.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { PackagesService } from '../../packages/packages.service';
import { DownloadLogsService } from '../../download-logs/download-logs.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';
import { LicensingService } from '../../licensing/licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UserGroupsService } from '../user-groups.service';
import { LoginLogsService } from '../../login-logs/login-logs.service';
import { AppSettingsService } from '../../app-settings/app-settings.service';
import { ProductsService } from '../../products/products.service';
import { EmailQueueService } from '../../email-queue/email-queue.service';
import { LoginService } from '../../../login/login.service';
import { ZenCustomersService } from '../../zen-customers/zen-customers.service';
import { ReportsService } from '../../reports/reports.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { ClicksTableComponent } from 'client/app/components/shared/clicks-table/clicks-table.component';
import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';
import { LoginsTableComponent } from 'client/app/components/shared/logins-table/logins-table.component';
import { NotificationsTableComponent } from 'client/app/components/shared/notifications-table/notifications-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';
import { DownloadableBuildsTableComponent } from 'client/app/components/shared/downloadable-builds-table/downloadable-builds-table.component';
import { DownloadableDocumentsTableComponent } from 'client/app/components/shared/downloadable-documents-table/downloadable-documents-table.component';
import { OrganizationsTableComponent } from 'client/app/components/shared/organizations-table/organizations-table.component';
import { KeysTableComponent } from 'client/app/components/shared/keys-table/keys-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	acl = AccessControl;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;
	sharedLicenseTools = SharedLicenseTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('logsTable2') logsTable2: LogsTableComponent = null;
	@ViewChild('clicksTable1') clicksTable1: ClicksTableComponent = null;
	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;
	@ViewChild('loginsTable1') loginsTable1: LoginsTableComponent = null;
	@ViewChild('notificationsTable1') notificationsTable1: NotificationsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;
	@ViewChild('dBuildsTable1') dBuildsTable1: DownloadableBuildsTableComponent = null;
	@ViewChild('dDocsTable1') dDocsTable1: DownloadableDocumentsTableComponent = null;
	@ViewChild('orgsTable1') orgsTable1: OrganizationsTableComponent = null;
	@ViewChild('orgsTable2') orgsTable2: OrganizationsTableComponent = null;
	@ViewChild('orgsTable3') orgsTable3: OrganizationsTableComponent = null;
	@ViewChild('keysTable1') keysTable1: KeysTableComponent = null;
	@ViewChild('keysTable2') keysTable2: KeysTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	now = new Date();

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	user: Models.User;

	isStaff: boolean = false;

	// other stuff
	canEdit: boolean = false;
	canDelete: boolean = false;
	canManageAccess: boolean = false;
	canManageNotifications: boolean = false;
	canManageZenStaffAccess: boolean = false;
	canPromote: boolean = false;
	canImpersonate: boolean = false;

	canSetupSalesEngineerNotifications: boolean = false;
	canSetupAccountOwnerNotifications: boolean = false;
	hasAccessToAllBuildsAndDocs: boolean = false;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	organization: Models.Organization;

	assignedTagGroupings = {};
	roleTagGroupings = {};
	userGroupTagGroupings = {};
	showAccessTags: boolean = false;

	showNotificationSettings: boolean = false;

	// accessTagGroupings = MiscTools.getAccessTagsGroupings();

	buildDownloads: Models.DownloadableBuild[] = [];
	documentDownloads: Models.DownloadableDocument[] = [];
	packages: Models.Package[] = [];
	userKeys: Models.LPActivation[] = [];
	userKeys2: Models.LPActivation[] = [];
	otherOrgKeys: Models.LPActivation[] = [];
	nowSharedKeys: number[] = [];
	showShareableKeys: boolean = false;
	userHostIdLabels: Models.UserHostId[] = [];
	downloadLogs: Models.DownloadLog[] = [];
	loginHistory: Models.LoginHistory[] = [];
	userGroups: Models.UserGroup[] = [];
	managerGroups: Models.UserGroup[] = [];
	adminLogsOnUser: Models.AdminLog[] = [];
	adminLogsByUser: Models.AdminLog[] = [];
	notifyLogs: Models.EmailQueueEntry[] = [];
	userSnoozes: Models.UserLicenseKeySnooze[] = [];
	zenUsers: Models.ZenMasterUser[] = [];

	bundles: Models.Bundle[] = [];
	showBundles: boolean = false;

	canAddJournals: boolean = false;
	journals: Models.JournalEntry[] = [];

	keyActiveHostCounts = {};
	recentHostIconPopup = '# Host IDs eporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days';

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	salesforceContactID = '';
	salesforceLeadID = '';
	showWelcome: boolean = false;
	showExpiredPasswordToken: boolean = false;
	showLinkToSalesforce: boolean = false;

	onboardingStatus: string[] = [];
	possibleIssues: string[] = [];
	seOrgs: Models.Organization[] = [];
	tamOrgs: Models.Organization[] = [];
	linkLogs: Models.LinkLog[] = [];

	accountOwnerOrgs: Models.Organization[] = [];

	expandWarnings: boolean = false;

	salesforceContactInfoBlock = '';
	salesforceLeadInfoBlock = '';
	sfFetching: boolean = false;

	products: Models.Product[] = [];
	keyProducts: Models.LicenseProduct[] = [];
	savedSearches: Models.SavedLicenseSearch[] = [];
	showSavedSearches: boolean = false;

	salesForceUrl = '';

	flagsToShow: string[] = [];

	regPropsToShow: any[] = [];

	userTemplates: Models.KeyTemplate[] = [];
	groupTemplates: Models.KeyTemplate[] = [];
	showUserTemplates: boolean = false;
	showGroupTemplates: boolean = false;

	staffMeterReportOrgs: Models.Organization[] = [];

	// showBxProtocolStats: boolean = false;

	zenStaffAccessLevel: string = '';
	zenStaffAccessLabel: string = '';
	zenStaffSettableLevels: any[] = [];

	potentialOrgs: Models.Organization[] = [];

	cleanupInfo: any = null;
	cleanupWarning: string = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private organizationsService: OrganizationsService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private packagesService: PackagesService,
		private downloadLogsService: DownloadLogsService,
		private adminLogsService: AdminLogsService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService,
		private linkLogsService: LinkLogsService,
		private loginLogsService: LoginLogsService,
		private userGroupsService: UserGroupsService,
		private productsService: ProductsService,
		private emailQueueService: EmailQueueService,
		private appSettingsService: AppSettingsService,
		private zenCustomersService: ZenCustomersService,
		private reportsService: ReportsService,
		private loginService: LoginService
	) {
		this.route.paramMap.subscribe((params) => {
			this.id = +params.get('id');
			this.user = this.usersService.getOne(this.id);
			if (!this.user || this.user == null || this.user.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				TrackRecent.addRecent(this.id, 'user');
				this.userSubscription = this.authService.user.subscribe((authUser) => {
					this.authUser = authUser;
					this.loadData();
				});
				this.usersService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		const expandWarnings = localStorage.getItem('cp-users.keys.expandWarnings');
		this.expandWarnings = (expandWarnings && expandWarnings === 'true');
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		// this.showBxProtocolStats = TextTools.getUserPropValue(this.user, AppConstants.showBxProtocolStatsOverride) === 'yes';

		this.flagsToShow = [];
		for (const flag of AppConstants.userFlags)
			if (this.user.flags && this.user.flags![flag.key] && +this.user.flags[flag.key] === 1)
				this.flagsToShow.push(flag.label);

		this.buildDownloads = [];
		this.documentDownloads = [];
		this.packages = [];
		this.userKeys = [];
		this.userKeys2 = [];
		this.otherOrgKeys = [];
		this.userHostIdLabels = [];
		this.downloadLogs = [];
		this.loginHistory = [];
		this.userGroups = [];
		this.managerGroups = [];
		this.adminLogsOnUser = [];
		this.adminLogsByUser = [];
		this.journals = [];
		this.seOrgs = [];
		this.tamOrgs = [];
		this.accountOwnerOrgs = [];
		this.notifyLogs = [];
		this.savedSearches = [];
		this.staffMeterReportOrgs = [];

		this.assignedTagGroupings = {};
		this.roleTagGroupings = {};
		this.userGroupTagGroupings = {};
		this.keyActiveHostCounts = {};

		this.zenStaffAccessLevel = '';
		this.zenStaffAccessLabel = '';
		this.zenStaffSettableLevels = [];

		this.potentialOrgs = [];
		this.userSnoozes = [];

		this.zenUsers = [];

		// wipe sub-components
		if (this.logsTable1) this.logsTable1.updateContent([], 'cp-user-view-admin-logs', {});
		if (this.logsTable2) this.logsTable2.updateContent([], 'cp-user-view-action-logs', {});
		if (this.clicksTable1) this.clicksTable1.updateContent([], 'cp-user-view-click-logs', {});
		if (this.downloadsTable1) this.downloadsTable1.updateContent([], 'cp-user-view-download-logs', {});
		if (this.loginsTable1) this.loginsTable1.updateContent([], 'cp-user-view-login-logs', {});
		if (this.notificationsTable1) this.notificationsTable1.updateContent([], 'cp-user-view-notification-logs', {});
		if (this.journalsTable1) this.journalsTable1.updateContent([]);
		if (this.dBuildsTable1) this.dBuildsTable1.updateContent([], 'cp-user-view-downloadable-builds');
		if (this.dDocsTable1) this.dDocsTable1.updateContent([], 'cp-user-view-downloadable-documents');
		if (this.keysTable1) this.keysTable1.updateContent([], 'cp-user-view-active-keys');
		if (this.keysTable2) this.keysTable2.updateContent([], 'cp-user-view-old-keys');

		this.isStaff = ValidationTools.checkRole(this.user.role, AppConstants.staffUserRole);

		const allOrgs: Models.Organization[] = this.organizationsService.getAll();
		this.onboardingStatus = ValidationTools.getUserIssues(this.user);
		this.possibleIssues = ValidationTools.getPossibleUserIssues(this.user);

		this.cleanupInfo = ValidationTools.workOutUserCleanUp(this.user);
		if (this.cleanupInfo && this.cleanupInfo.cleanupDate && MiscTools.diffDays(this.cleanupInfo.cleanupDate, new Date()) <= 30) {
			this.cleanupWarning = 'This account will be deleted on ' + TextTools.formatDateNiceUTC(this.cleanupInfo.cleanupDate, false) + '.\n'
				+ 'Info: ' + this.cleanupInfo.reason + '\n'
				+ 'Reason: More than ' + this.cleanupInfo.maxDays + ' days have passed since '
				+ TextTools.formatDateNiceUTC(this.cleanupInfo.baseDate, false) + ' (' + this.cleanupInfo.baseDateType + ')';
		} // if

		this.products = this.productsService.getAll();

		if (this.user.use_sso === 0 && !this.user.last_login
			&& this.user.reset_password_token && this.user.reset_password_expires !== null) {
			const now = new Date();
			const exp = new Date(this.user.reset_password_expires);
			if (now.getTime() > exp.getTime()) {
				this.showExpiredPasswordToken = true;
				this.showWelcome = true;
			}

		} else if (this.user.use_sso === 0 && !this.user.last_login
			&& (!this.user.reset_password_token || this.user.reset_password_token === '')
			&& this.user.edited_by !== this.user.id) {
			this.showWelcome = true;
		}

		const keyTemplates: Models.KeyTemplate[] = await this.licensingAdminService.getTemplates();
		this.userTemplates = [];
		this.groupTemplates = [];

		for (const keyTemplate of keyTemplates) {
			const toUser = (keyTemplate.user_ids && keyTemplate.user_ids.includes(this.id));
			let toGroup = false;
			if (keyTemplate.user_group_ids && this.user.group_ids)
				for (const gID of this.user.group_ids)
					if (keyTemplate.user_group_ids.includes(gID))
						toGroup = true;

			if (toUser)
				this.userTemplates.push(keyTemplate);

			if (toGroup)
				this.groupTemplates.push(keyTemplate);

		} //for

		this.regPropsToShow = [];
		const propFieldsToShow = AppConstants.selfRegistrationUserProps.concat(
			[
				'user_reg_code',
				AppConstants.marketoLeadIDKey,
				// AppConstants.showBxProtocolStatsOverride
			]);

		for (const prop of propFieldsToShow) {
			const propValue = TextTools.getUserPropValue(this.user, prop);
			if (propValue && propValue !== '') {
				this.regPropsToShow.push({
					label: prop.replace('user_reg_', '').replace(/_/g, ' '),
					value: propValue
				});
			}
		}

		this.salesForceUrl = await this.appSettingsService.getSalesForceUrl();

		this.salesforceLeadID = this.getUserPropValue(AppConstants.salesforceLeadIDKey);
		this.salesforceContactID = this.getUserPropValue(AppConstants.salesforceContactIDKey);

		if (this.user.org_id && this.user.org_id !== 0) {
			this.organization = this.organizationsService.getOne(this.user.org_id);
			if (!this.isStaff
				&& this.organization && this.organization.salesforce_account_id &&
				this.organization.salesforce_account_id !== ''
				&& (!this.salesforceContactID || this.salesforceContactID === ''))
				this.showLinkToSalesforce = true;
		} // if

		if (this.authUser.id !== this.id) {

			if (this.organization && ValidationTools.hasFlag(this.organization, 'restricted')) {
				this.canEdit = ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole);
			} else {
				this.canEdit = AppConstants.manageableRoles[this.authUser.role].includes(this.user.role)
					&& ((!!this.organization && ValidationTools.checkAccess(this.authUser, 'manage-basic-users-' + this.organization.otype))
						|| (!this.organization && ValidationTools.checkAccess(this.authUser, 'manage-basic-users-no-org')));
			} // if

			// never logged in, never welcomed, not SSO
			const unusedBasicUser = this.user.role === AppConstants.basicUserRole
				&& this.user.last_login == null
				&& this.user.reset_password_expires == null
				&& this.user.use_sso === 0;

			this.canDelete = this.canEdit && (ValidationTools.checkAccess(this.authUser, 'delete-any-user')
				|| (unusedBasicUser && ValidationTools.checkAccess(this.authUser, 'delete-unused-basic-users')));

			// this.canManageNotifications = ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);
			this.canManageNotifications = this.canEdit
				&& (this.user.role === AppConstants.basicUserRole || ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole));

			this.canImpersonate = ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole)
				&& this.usersService.isImpersonateEnabled();
		} // if

		this.canManageZenStaffAccess = ValidationTools.checkAccess(this.authUser, 'admin-zen-master-staff');
		this.canAddJournals = this.authUser && ValidationTools.checkAccess(this.authUser, 'add-journals');

		// a user can manage the access of another user if
		// the user doing the managing is admin and the user getting managed is at least readonly
		this.canManageAccess = this.authUser && ValidationTools.checkRole(this.user.role, AppConstants.staffUserRole)
			&& ValidationTools.checkRole(this.authUser.role, AppConstants.adminUserRole);

		this.canPromote = this.user.role === AppConstants.basicUserRole
			&& this.user.use_sso === 1
			&& ValidationTools.checkAccess(this.authUser, 'promote-basic-to-staff');

		const domain = TextTools.getEmailDomain(this.user.email).toLowerCase();

		for (const org of allOrgs) {
			org['issues' + ''] = ValidationTools.getOrganizationIssues(org, true).join(' ');

			if (org.salesforce_account_owner_id && org.salesforce_account_owner_id === this.id) this.accountOwnerOrgs.push(org);
			if (org.salesforce_se_id && org.salesforce_se_id === this.id) this.seOrgs.push(org);
			if (org.salesforce_tam_id && org.salesforce_tam_id === this.id) this.tamOrgs.push(org);

			if (this.canEdit && this.organization == null && org.email_domains && org.email_domains !== '') {
				const splitDomains: string[] = org.email_domains.split(',');
				let match = false;
				for (const splitDomain of splitDomains)
					if (splitDomain.trim().toLowerCase() === domain)
						match = true;
				if (match) this.potentialOrgs.push(org);
			} // if
		} // for

		this.accountOwnerOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);
		this.seOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);
		this.tamOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);
		this.potentialOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);

		if (this.seOrgs.length > 0 || this.accountOwnerOrgs.length > 0)
			await MiscTools.delay(100);

		if (this.orgsTable1)
			this.orgsTable1.updateContent(this.seOrgs, 'cp-user-view-se-org-list', { showFavorite: false, showSalesEng: true, showSalesRep: true });

		if (this.orgsTable2)
			this.orgsTable2.updateContent(this.accountOwnerOrgs, 'cp-user-view-sales-rep-org-list', { showFavorite: false, showSalesEng: true, showSalesRep: true });

		if (this.orgsTable3)
			this.orgsTable3.updateContent(this.tamOrgs, 'cp-user-view-tam-org-list', { showFavorite: false, showSalesEng: true, showSalesRep: true });

		if (this.user.notifications && this.user.notifications.receiveStaffOrgMeterReportMessages
			&& this.user.notifications.orgMeterReportOrgIDs
			&& this.user.notifications.orgMeterReportOrgIDs.length > 0)
			for (const org of allOrgs)
				if (this.user.notifications.orgMeterReportOrgIDs.includes(org.id))
					this.staffMeterReportOrgs.push(org);

		this.staffMeterReportOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);

		for (const accessControlTagGrouping of AccessControl.accessControlTagGroupings) {
			for (const tag of accessControlTagGrouping.tags) {
				if (this.user.directAccessTags.includes(tag)) {
					if (!this.assignedTagGroupings[accessControlTagGrouping.grouping])
						this.assignedTagGroupings[accessControlTagGrouping.grouping] = [];
					this.assignedTagGroupings[accessControlTagGrouping.grouping].push(tag);
				}
				if (this.user.roleAccessTags.includes(tag)) {
					if (!this.roleTagGroupings[accessControlTagGrouping.grouping])
						this.roleTagGroupings[accessControlTagGrouping.grouping] = [];
					this.roleTagGroupings[accessControlTagGrouping.grouping].push(tag);
				}
				if (this.user.groupAccessTags.includes(tag)) {
					if (!this.userGroupTagGroupings[accessControlTagGrouping.grouping])
						this.userGroupTagGroupings[accessControlTagGrouping.grouping] = [];
					this.userGroupTagGroupings[accessControlTagGrouping.grouping].push(tag);
				}
			}
		}

		this.addedByUser = null;
		if (this.user.added_by && this.user.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.user.added_by);

		this.editedByUser = null;
		if (this.user.edited_by && this.user.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.user.edited_by);

		// get packages for the user...
		for (const pkgID of this.user.package_ids) {
			const pkg = this.packagesService.getOne(pkgID);
			if (pkg) this.packages.push(pkg);
		}
		this.packages.sort((a, b) => (a.name > b.name) ? 1 : -1);

		const allUserGroups: Models.UserGroup[] = this.userGroupsService.getAll();

		for (const group of allUserGroups) {
			if (group.manager_ids.includes(this.id))
				this.managerGroups.push(group);
			if (group.user_ids.includes(this.id))
				this.userGroups.push(group);
		} // for
		this.managerGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);
		this.userGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);

		// this.zenUsers = await this.zenCustomersService.getZenUsersByEmail(this.user.email);
		// console.log(this.zenUsers);

		await this.setupZenStaffStuff();

		this.bundles = await this.usersService.getUserBundles(this.id);

		this.keyProducts = await this.licensingAdminService.getProducts();
		this.savedSearches = await this.licensingService.getSavedSearchesForUser(this.id);

		this.canSetupSalesEngineerNotifications = this.isStaff && ValidationTools.checkAccess(this.authUser, 'setup-user-with-sales-engineer-notifications');
		this.canSetupAccountOwnerNotifications = this.isStaff && ValidationTools.checkAccess(this.authUser, 'setup-user-with-account-owner-notifications');

		this.userSnoozes = await this.usersService.getUserKeySnoozes(this.id);

		this.keyActiveHostCounts = await this.usersService.getActiveHostCounts(this.id);

		await this.loadUserKeys();

		this.userHostIdLabels = await this.usersService.getUserHostIdLabels(this.id);

		this.hasAccessToAllBuildsAndDocs = this.isStaff && ValidationTools.checkAccess(this.user, 'access-all-builds-and-documents');

		if (!this.hasAccessToAllBuildsAndDocs) {
			this.buildDownloads = await this.myBuildsService.fetchBuildsForUserFromDB(this.id);
			if (this.dBuildsTable1) this.dBuildsTable1.updateContent(this.buildDownloads, 'cp-user-view-downloadable-builds');

			this.documentDownloads = await this.myDocumentsService.fetchDocumentsForUserFromDB(this.id);
			if (this.dDocsTable1) this.dDocsTable1.updateContent(this.documentDownloads, 'cp-user-view-downloadable-documents');
		} // if

		this.downloadLogs = await this.downloadLogsService.getLogsForUser(this.id);
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.downloadLogs, 'cp-user-view-download-logs', { showUserInfo: false, linkUser: false });

		this.loginHistory = await this.loginLogsService.getLogsForUser(this.id);
		if (this.loginsTable1)
			this.loginsTable1.updateContent(this.loginHistory, 'cp-user-view-login-logs', { showUserInfo: false, linkUser: false });

		this.linkLogs = await this.linkLogsService.getLogsForUser(this.id);
		if (this.clicksTable1)
			this.clicksTable1.updateContent(this.linkLogs, 'cp-user-view-click-logs', { showUserInfo: true, linkUser: true });

		this.adminLogsOnUser = await this.adminLogsService.getLogs(['user', 'authentication'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogsOnUser, 'cp-user-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true, });

		this.adminLogsByUser = await this.adminLogsService.getLogs([], 0, [], null, null, [this.id]);
		if (this.logsTable2)
			this.logsTable2.updateContent(this.adminLogsByUser, 'cp-user-view-action-logs', { showUserInfo: false, showObjectInfo: true, linkObject: true, linkUser: false, });

		this.journals = await this.adminLogsService.getJournals('user', this.id);
		if (this.journalsTable1)
			this.journalsTable1.updateContent(this.journals);

		this.notifyLogs = await this.emailQueueService.getQueue([], [], [], [this.id], null, null, false);
		if (this.notificationsTable1)
			this.notificationsTable1.updateContent(this.notifyLogs, 'cp-user-view-notification-logs', { showUserInfo: false, linkUser: false });

		this.loading = false;
	}

	// *********************************************************
	async loadUserKeys() {

		this.userKeys = [];
		this.userKeys2 = [];

		const tmpKeys1: Models.LPActivation[] = [];
		const tmpKeys2: Models.LPActivation[] = [];
		const userKeys: Models.UserLicenseKey[] = await this.usersService.getUserKeys(this.id);
		for (const userKey of userKeys) {
			let item: Models.LPActivation = userKey.activation;

			if (userKey.is_deleted === 1) item['_extra_warnings'] = 'The user has placed this key in the trash.';

			const snoozes: Models.UserLicenseKeySnooze[] = [];
			for (const snooze of this.userSnoozes)
				if (snooze.user_key_id === userKey.id)
					snoozes.push(snooze);

			if (snoozes.length > 0)
				item['__snoozes'] = this.makeSnoozeTooltip(snoozes);
			else
				item['__snoozes'] = '';

			item['__niceProduct'] = this.niceKeyProduct(item.product);
			item['__popover'] = PopOverTools.getKeyPopoverLines(item, this.keyProducts, [], [], false).join('\n');

			item['__user_id'] = userKey.id;
			item['__user_label'] = userKey.label;

			if (this.keyActiveHostCounts[item.id])
				item['__active_hostids'] = this.keyActiveHostCounts[item.id];
			else
				item['__active_hostids'] = '';

			const exp = SharedLicenseTools.getKeyExpiration(item, '', true);
			if ((item.enabled === 0 && !SharedLicenseTools.isSpecialKey(item)) || (exp != null && MiscTools.hasExpired(exp)))
				tmpKeys2.push(item);
			else
				tmpKeys1.push(item);

		} // for

		this.userKeys = tmpKeys1;
		this.userKeys2 = tmpKeys2;

		if (this.keysTable1) {
			if (this.expandWarnings && !this.keysTable1.expandWarnings) this.keysTable1.toggleWarnings();

			this.keysTable1.updateContent(this.userKeys, 'cp-user-view-active-keys',
				{
					staffMode: true,
					addPopovers: true,

					showStaffDelete: this.canEdit || this.id === this.authUser.id,
					showEnabled: true,
					showUserLabel: true,
					showInfo: true,
					showOrganization: true,
					showFullProduct: false,
					showFullType: false,
					showExpandedActivations: true,
					showNumUsers: true,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: true,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: true,
					showMeterIrregularities: true
				});
		} // if

		if (this.keysTable2) {
			if (this.expandWarnings && !this.keysTable2.expandWarnings) this.keysTable2.toggleWarnings();

			this.keysTable2.updateContent(this.userKeys2, 'cp-user-view-old-keys',
				{
					staffMode: true,
					addPopovers: true,

					showStaffDelete: this.canEdit || this.id === this.authUser.id,
					showEnabled: true,
					showUserLabel: true,
					showInfo: true,
					showOrganization: true,
					showFullProduct: false,
					showFullType: false,
					showExpandedActivations: true,
					showNumUsers: true,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: true,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: true,
					showMeterIrregularities: true
				});
		} // if

		this.otherOrgKeys = [];
		this.nowSharedKeys = [];
		if (this.canEdit && this.organization && this.user.is_enabled === 1) {
			// get all org keys
			const orgKeys = await this.organizationsService.fetchKeys(this.organization.id);

			// filter out ones already shared + expired and disabled ones...
			const tmpList: Models.LPActivation[] = [];
			for (const activation of orgKeys) {
				const keyExp: Date = SharedLicenseTools.getKeyExpiration(activation, '', true);
				const idx = MiscTools.findIndexGeneric(userKeys, 'activation_id', activation.id);
				if (idx === -1 && activation.enabled === 1 && !SharedLicenseTools.isSpecialKey(activation) && (keyExp == null || !MiscTools.hasExpired(keyExp)))
					tmpList.push(activation);
			} // for

			if (tmpList.length <= 100) this.otherOrgKeys = tmpList;
			this.otherOrgKeys.sort((a, b) => (a.key > b.key) ? 1 : -1);
			this.otherOrgKeys.sort((a, b) => (a.product > b.product) ? 1 : -1);
		} // if
	} // 

	// *********************************************************
	async shareKeyWithUser(keyToShare: Models.LPActivation) {
		if (confirm('Are you sure you want to share this key with this user?')) {
			try {
				this.loading = true;
				await this.usersService.registerKeys(this.id, [{ key: keyToShare.key, label: '' }], false, 'zcp-staff-from-user-view');
				this.nowSharedKeys.push(keyToShare.id);
				await this.loadUserKeys();
				this.uiAlertsService.addMsg('This key has been shared with ' + this.user.name + '.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} catch (e) {
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
			this.loading = false;
		} // if
	} //

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete User',
			'If you delete this User, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete User', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.usersService.deleteOne(this.user.id);
		if (result) {
			this.uiAlertsService.addMsg('The user account for ' + this.user.name + ' has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.users]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this User?')) {
			const result = await this.usersService.toggleEnabled(this.user.id);
			if (result) {
				this.user = result;
				this.loadData();
				// this.uiAlertsService.addMsg('The user account has been disabled.',
				// 		'info', 'ACCOUT_STATUS', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this User?')) {
			const result = await this.usersService.toggleEnabled(this.user.id);
			if (result) {
				this.user = result;
				this.loadData();
				// this.uiAlertsService.addMsg('The user account has been enabled.',
				// 		'info', 'ACCOUT_STATUS', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} else {
				return false;
			}
		}
	}

	async updatePasswordResetToken() {
		if (confirm('Are you sure you want to send this user a password reset token?')) {
			const result = await this.usersService.updatePasswordResetToken(
				this.user.id
			);
			if (result) {
				this.user = result;
				this.uiAlertsService.addMsg(
					'A password reset token has been set for this user and they\'ve been sent an E-Mail.',
					'info',
					'PASSWORD_RESET',
					false,
					AppConstants.standardMessageAutoCloseTimeSecs
				);
			} else {
				return false;
			}
		}
	}

	async sendWelcomeMessage() {
		if (confirm('Are you sure you want to send this user a welcome message?')) {
			this.showWelcome = false;
			this.showExpiredPasswordToken = false;
			const result = await this.usersService.sendWelcomeMessage(this.user.id);
			if (result) {
				this.user = result;
				this.uiAlertsService.addMsg(
					'A password reset token has been set for this user and they\'ve been sent a \"welcome\" E-Mail.',
					'info',
					'WELCOME_MESSAGE',
					false,
					AppConstants.standardMessageAutoCloseTimeSecs
				);
			} else {
				return false;
			}
		}
	}

	async recover() {
		alert('Not yet built...');
	}

	async linkToSalesForce() {
		const sfContacts = await this.organizationsService.fetchSalesforceContacts(this.organization.salesforce_account_id);
		if (sfContacts) {
			this.showLinkToSalesforce = false;
			let sfID = '';
			for (const contact of sfContacts)
				if (contact.email.toLowerCase() === this.user.email.toLowerCase())
					sfID = TextTools.getUserPropValue(contact, AppConstants.salesforceContactIDKey);

			if (sfID === '') {
				this.uiAlertsService.addMsg(
					'Could not find a matching Contact in Salesforce.',
					'warning',
					'NO_LINK',
					false,
					0
				);
			} else {
				await this.usersService.setSalesforceContactID(this.user.id, sfID);
				this.salesforceContactID = sfID;
			}
		}
	}

	async unsubscribeFromAllNotifications() {
		if (confirm('Are you sure you want to unsubscribe this user from all notifications?')) {
			const nullSettings: any = {};
			await this.usersService.updateNotificationSettings(this.id, nullSettings);
			this.user.notifications = null;
		} // if
	}

	async setNotificationsToDefaults() {
		const settings = new Models.NotificationSettings();
		await this.usersService.updateNotificationSettings(this.id, settings);
		this.user.notifications = settings;
	}

	// async showProtocolStats() {
	// 	if (confirm('Are you prepared to support this user when they have questions about protocol stats?')) {
	// 		// setUserProperty
	// 		this.loading = true;
	// 		const result = await this.usersService.setUserProperty(this.user.id, AppConstants.showBxProtocolStatsOverride, 'yes');
	// 		if (result) {
	// 			this.user = result;
	// 			this.showBxProtocolStats = TextTools.getUserPropValue(this.user, AppConstants.showBxProtocolStatsOverride) === 'yes';
	// 			this.uiAlertsService.addMsg('The user account will be able to see protocol stats for keys/host IDs the next time they login.',
	// 				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
	// 		} else {
	// 			return false;
	// 		}
	// 		this.loading = false;
	// 	}
	// }

	// async hideProtocolStats() {
	// 	if (confirm('Are you sure?')) {
	// 		this.loading = true;
	// 		const result = await this.usersService.setUserProperty(this.user.id, AppConstants.showBxProtocolStatsOverride, 'no');
	// 		if (result) {
	// 			this.user = result;
	// 			this.showBxProtocolStats = TextTools.getUserPropValue(this.user, AppConstants.showBxProtocolStatsOverride) === 'yes';
	// 			this.uiAlertsService.addMsg('The user account will no longer be able to see protocol stats.',
	// 				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
	// 		} else {
	// 			return false;
	// 		}
	// 		this.loading = false;
	// 	}
	// }

	isUserSelfRegistered() {
		for (const prop of AppConstants.selfRegistrationUserProps) {
			const val = this.getUserPropValue(prop);
			if (val && val !== '')
				return true;
		}
		return false;
	}

	getUserPropValue(propKey: string): string {
		return TextTools.getUserPropValue(this.user, propKey);
	}

	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	getFileCount(dl: Models.DownloadableBuild): number {
		let count = 0;
		if (dl.build.platform_files) count += dl.build.platform_files.length;
		if (dl.build.release_notes_file_id && dl.build.release_notes_file_id !== 0)
			count++;
		return count;
	}

	routeRow(objType: string, action: string, id: number) {
		const route = MiscTools.getRoute(objType, action, id);
		if (route && route.length !== 0) this.router.navigate(route);
	}

	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].label;
	}

	toggleWarnings() {
		this.expandWarnings = !this.expandWarnings;
		if (this.keysTable1) this.keysTable1.toggleWarnings();
		if (this.keysTable2) this.keysTable2.toggleWarnings();
		localStorage.setItem('cp-users.keys.expandWarnings', this.expandWarnings.toString());
	}

	async getSalesforceInfoBlocks() {
		this.sfFetching = true;
		if (this.salesforceContactID !== '')
			this.salesforceContactInfoBlock = await this.organizationsService.makeSalesForceObjectBlock('contacts', this.salesforceContactID);

		if (this.salesforceLeadID !== '')
			this.salesforceLeadInfoBlock = await this.organizationsService.makeSalesForceObjectBlock('leads', this.salesforceLeadID);

		this.sfFetching = false;
	}

	getGroupings(tagGroupings: any): string[] {
		return Object.keys(tagGroupings);
	}

	showProductNames(ids: number[]): string {
		const names: string[] = [];
		for (const id of ids) {
			const idx = MiscTools.findIndex(this.products, id);
			if (idx !== -1) names.push(this.products[idx].name);
		}
		return names.join(', ');
	}

	showSavedSearchNames(ids: number[]): string {
		const names: string[] = [];
		for (const id of ids) {
			const idx = MiscTools.findIndex(this.savedSearches, id);
			if (idx !== -1) names.push(this.savedSearches[idx].name);
		}
		return names.join(', ');
	}

	runSavedSearch(searchOptions: string) {
		localStorage.setItem('cp-licensingSearch.searchOptions', JSON.stringify(SharedLicenseTools.parseKeySearchSettings(searchOptions)));
		localStorage.setItem('CLEAN.licensingSearch.autoRun', 'yes');
		this.router.navigate(['/' + AppConstants.urls.licensing]);
	}

	async setupZenStaffStuff() {
		this.zenStaffAccessLevel = await this.usersService.getZenStaffAccess(this.id);
		this.zenStaffAccessLabel = '';
		this.zenStaffSettableLevels = [];

		if (this.zenStaffAccessLevel && this.zenStaffAccessLevel !== '') {
			const idx = MiscTools.findIndexGeneric(AppConstants.zenStaffAccessLevels, 'level', this.zenStaffAccessLevel);
			if (idx !== -1) {
				this.zenStaffAccessLabel = AppConstants.zenStaffAccessLevels[idx].label;
				if (this.canManageZenStaffAccess && this.zenStaffAccessLevel !== 'no-account') {
					for (const zenStaffAccessLevel of AppConstants.zenStaffAccessLevels) {
						if (zenStaffAccessLevel.level !== 'no-account' && zenStaffAccessLevel.level !== this.zenStaffAccessLevel)
							this.zenStaffSettableLevels.push(zenStaffAccessLevel);
					} // for
				} // if
			} // if
		} // if
	}

	async changeZenSupportLevel(level: string) {
		this.loading = true;

		try {
			await this.usersService.setZenStaffAccess(this.id, level);
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		await this.setupZenStaffStuff();
		this.loading = false;
	}

	async promoteBasicToStaff() {
		this.loading = true;
		try {
			if (confirm('Are you sure you want to promote this User to staff?')) {
				const result = await this.usersService.promoteBasicToStaff(this.user.id);
				if (result) {
					this.user.role = AppConstants.staffUserRole;
					this.canPromote = false;
				} // if
			}
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
		this.loading = false;
	}

	async impersonate() {
		this.loading = true;

		this.loginService.impersonate(this.id)
			.subscribe(responseData => {
				// console.log('in login comp autolo');
				// console.log(responseData);
				if (responseData) {
					this.loginService.setLastURL('');
					this.router.navigate([AppConstants.defaultLandingPage]);
					this.loading = false;
				} else {
					this.loading = false;
				}
			}, () => {
				this.loading = false;
			});
	}

	async deleteUserKey(userKeyId: number, keyId: number) {
		try {
			if (confirm('Are you sure you want to remove this key from this user?')) {
				this.loading = true;
				await this.usersService.deregisterKey(this.id, userKeyId);
				await this.loadUserKeys();
				this.uiAlertsService.addMsg('The key has been removed from the user\'s account.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			} // if
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
		this.loading = false;
	}

	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}

	async emailUserKeysReport() {
		await this.usersService.emailUserKeysReport(this.id);
	}

	openUserKeysReport(metricType: string) {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.users + '/' + this.id + '/key-report'
			+ '?metricType=' + encodeURIComponent(metricType);
		window.open(url, '_blank');
	}

	async setupUserForSalesEngineerNotifications() {
		try {
			const result = await this.usersService.setupUserForSalesEngineerNotifications(this.user.id);
			if (result) {
				this.user = result;
				this.loadData();
				this.showNotificationSettings = true;
				this.showSavedSearches = true;
				this.uiAlertsService.addMsg('The user account has been setup.', 'info', 'se_notify', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			// this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	async setupUserForAccountOwnerNotifications() {
		try {
			const result = await this.usersService.setupUserForAccountOwnerNotifications(this.user.id);
			if (result) {
				this.user = result;
				this.loadData();
				this.showNotificationSettings = true;
				this.showSavedSearches = true;
				this.uiAlertsService.addMsg('The user account has been setup.', 'info', 'se_notify', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}

		} catch (e) {
			// this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

	}

	makeSnoozeTooltip(snoozes: Models.UserLicenseKeySnooze[]) {
		let tip = '';
		if (snoozes && snoozes.length > 0) {
			let snoozeTypes: string[] = [];
			for (const snooze of snoozes) {
				const exp = new Date(snooze.expires_at);
				let snoozeText = MiscTools.fetchLabel(AppConstants.keySnoozeTypes, snooze.snooze_type);
				if (snooze.expires_at != null && !isNaN(exp.getTime()))
					snoozeText += ' (expires ' + TextTools.formatDateNiceUTC(exp) + ')';
				snoozeTypes.push(snoozeText);
			} // for

			snoozeTypes = MiscTools.removeDuplicates(snoozeTypes)
			snoozeTypes.sort();
			tip = 'This user has snoozes/pauses of the following type(s) for this key: ' + snoozeTypes.join(', ');
		} // if
		return tip;
	}

	// *********************************************************
	async runDownloadsReport() {
		await this.reportsService.runReport('AllDownloadsReport', 'objType=user&objId=' + this.id);
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			deleteUserKey: (userKeyId: number, keyId: number) => {
				return this.deleteUserKey(userKeyId, keyId);
			},
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	}
}
