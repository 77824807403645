
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { UsersService } from '../users/users.service';
import { OrganizationsService } from '../organizations/organizations.service';
import TextTools from 'appshared/text-tools';

@Injectable({
	providedIn: 'root'
})
export class LicensingService {
	private lpUsers: Models.LPUser[];
	private protocols: string[];
	private brands: string[];
	private savedSearches: Models.SavedLicenseSearch[];

	constructor(
		private http: HttpClient,
		private usersService: UsersService,
		private organizationsService: OrganizationsService
	) {
	}

	// call back-end to find keys
	async searchKeys(args: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '?' + args;
			const result = await this.http.get<Models.LPActivation[]>(url).toPromise();
			const arr: Models.LPActivation[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get total search results...
	async getKeySearchTotal(args: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '?' + args + '&getTotal=yes';
			const result = await this.http.get<any>(url).toPromise();
			let count = 0;
			if (result && result['count']) count = +result['count'];
			const arr: Models.LPActivation[] = result;
			return count;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to find keys
	async searchOfflineLicenses(args: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/offline?' + args;
			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key
	async getOne(id: number) {
		try {
			const result = await this.http.get<Models.LPActivation>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + id).toPromise();
			const obj: Models.LPActivation = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key
	async getLots(ids: number[]) {
		try {
			const result = await this.http.get<Models.LPActivation[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation?ids=' + ids.join(',')).toPromise();
			const arr: Models.LPActivation[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's ZCP user(s)
	async getKeyUsers(id: number) {
		try {
			const result = await this.http.get<Models.UserLicenseKey[]>(AppConstants.apiUrl +
				AppConstants.apiUrls.licensing + '/activation/' + id + '/users').toPromise();
			const arr: Models.UserLicenseKey[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get old license portal user
	async getClassicLicenseUsers() {
		try {
			if (!this.lpUsers) {
				const result = await this.http.get<Models.LPUser[]>(AppConstants.apiUrl
					+ AppConstants.apiUrls.licensing + '/license-users').toPromise();
				this.lpUsers = result;
			} // if

			return this.lpUsers.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get old license portal user
	async getClassicLicenseUser(id: number) {
		try {
			let user = 'deleted user (id=' + id + ')';
			if (!this.lpUsers) {
				const result = await this.http.get<Models.LPUser[]>(AppConstants.apiUrl
					+ AppConstants.apiUrls.licensing + '/license-users').toPromise();
				this.lpUsers = result;
			} // if

			const idx = MiscTools.findIndexGeneric(this.lpUsers, 'id', id);
			if (idx !== -1) user = this.lpUsers[idx].email;

			return user;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's usage records
	async getActivationMonthlyUsage(id: number, metricType: string, billingCode: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/monthly-usage';
			url += '?metricType=' + encodeURIComponent(metricType);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);

			const result = await this.http.get<Models.LPMeterReportByMonth[]>(url).toPromise();
			const arr: Models.LPMeterReportByMonth[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's usage records
	async getActivationDailyUsageForMonth(id: number, product: string, year: number, month: number, billingCode: string = '', brand: string = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/daily-usage'
				+ '?product=' + encodeURIComponent(product) + '&year=' + encodeURIComponent(year) + '&month=' + encodeURIComponent(month);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);
			if (brand != null) url += '&brand=' + encodeURIComponent(brand);

			const result = await this.http.get<Models.LPMeterReportByDay[]>(url).toPromise();
			const arr: Models.LPMeterReportByDay[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async getHostMonthlyUsage(hostid: string, metricType: string, billingCode: string = '', activationID: number = 0) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/monthly-usage';
			url += '?metricType=' + encodeURIComponent(metricType);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);
			if (activationID !== 0) url += '&activationID=' + activationID;

			const result = await this.http.get<Models.LPMeterReportByMonth[]>(url).toPromise();
			const arr: Models.LPMeterReportByMonth[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's daily usage records for a month
	async getHostDailyUsageForMonth(hostid: string, product: string, year: number, month: number, billingCode: string = '', activationID: number = 0, brand: string = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/daily-usage'
				+ '?product=' + encodeURIComponent(product) + '&year=' + encodeURIComponent(year) + '&month=' + encodeURIComponent(month);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);
			if (activationID !== 0) url += '&activationID=' + activationID;
			if (brand != null) url += '&brand=' + encodeURIComponent(brand);

			const result = await this.http.get<Models.LPMeterReportByDay[]>(url).toPromise();
			const arr: Models.LPMeterReportByDay[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's active hosts
	async getActiveHostIDs(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/active-hosts';
			const result = await this.http.get<string[]>(url).toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to find specific activation(s) for a key
	async searchHosts(id: number, hostidFilter: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/find-hosts'
				+ '?hostids=' + encodeURIComponent(hostidFilter);

			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getKeysActiveLicenses(id: number, numDay: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/active-licenses?numDay=' + encodeURIComponent(numDay);
			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a licenses issued to a particular hostid
	async getHostLicenses(hostid: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/licenses';
			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a single license using id
	async getHostLicense(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(id) + '/license';
			const result = await this.http.get<Models.LPLicense>(url).toPromise();
			const obj: Models.LPLicense = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async getHostActivations(hostid: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/activations';
			const result = await this.http.get<Models.LPActivation[]>(url).toPromise();
			const arr: Models.LPActivation[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's version history
	async getVersionHistory(hostid: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/version-history';
			const result = await this.http.get<Models.LPVersionHistory[]>(url).toPromise();
			const arr: Models.LPVersionHistory[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get protocols
	async getProtocols() {
		try {
			if (!this.protocols) {
				const result = await this.http.get<string[]>(AppConstants.apiUrl
					+ AppConstants.apiUrls.licensing + '/protocols').toPromise();
				this.protocols = result;
				this.protocols.sort(SharedLicenseTools.protocolSort);
			} // if

			return this.protocols.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// get protocols
	async getBrands() {
		try {
			if (!this.brands) {
				const result = await this.http.get<string[]>(AppConstants.apiUrl
					+ AppConstants.apiUrls.licensing + '/brands').toPromise();
				this.brands = result;
				this.brands.sort();
			} // if

			return this.brands.slice();
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getProtocolChoices() {
		try {
			if (!this.protocols) {
				const result = await this.http.get<string[]>(AppConstants.apiUrl
					+ AppConstants.apiUrls.licensing + '/protocols').toPromise();
				this.protocols = result;
				this.protocols.sort(SharedLicenseTools.protocolSort);
			} // if

			const protocolChoices: any[] = [];
			// do them in order
			// Bx (not private)
			// Bx (private)
			// ZEN Master (MediaConnect)
			// ZEN Master (Not MediaConnect)

			for (const protocol of this.protocols)
				if (!protocol.startsWith('mediaconnect_') && !protocol.startsWith('zm_') && !protocol.startsWith('private_'))
					protocolChoices.push({ value: protocol, label: SharedLicenseTools.niceProtocol(protocol, false) });

			for (const protocol of this.protocols)
				if (!protocol.startsWith('mediaconnect_') && !protocol.startsWith('zm_') && protocol.startsWith('private_'))
					protocolChoices.push({ value: protocol, label: SharedLicenseTools.niceProtocol(protocol, false) });

			for (const protocol of this.protocols)
				if (protocol.startsWith('mediaconnect_'))
					protocolChoices.push({ value: protocol, label: SharedLicenseTools.niceProtocol(protocol, false) });

			for (const protocol of this.protocols)
				if (protocol.startsWith('zm_'))
					protocolChoices.push({ value: protocol, label: SharedLicenseTools.niceProtocol(protocol, false) });

			// for (const protocol of this.protocols)
			// 	protocolChoices.push({ value: protocol, label: SharedLicenseTools.niceProtocol(protocol, false) });

			return protocolChoices;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getBrandChoices() {
		try {
			if (!this.brands) {
				const result = await this.http.get<string[]>(AppConstants.apiUrl
					+ AppConstants.apiUrls.licensing + '/brands').toPromise();
				this.brands = result;
				this.brands.sort();
			} // if

			const brandChoices: any[] = [];
			for (const brand of this.brands)
				brandChoices.push({ value: brand, label: brand.toUpperCase() });


			return brandChoices;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a saved search
	async addSavedSearch(obj: Models.SavedLicenseSearch) {
		try {
			const result = await this.http.post<Models.SavedLicenseSearch>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/saved-searches', obj).toPromise();
			await this.getSavedSearches(true);

			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add a saved search
	async updateSavedSearch(id: number, obj: Models.SavedLicenseSearch) {
		try {
			const result = await this.http.put<Models.SavedLicenseSearch>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/saved-searches/' + id, obj).toPromise();
			await this.getSavedSearches(true);
			return result;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to delete a saved search
	async deleteSavedSearch(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/saved-searches/' + id).toPromise();
			await this.getSavedSearches(true);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getSavedSearchesForUser(id: number) {
		const result = await this.http.get<Models.SavedLicenseSearch[]>(AppConstants.apiUrl
			+ AppConstants.apiUrls.licensing + '/saved-searches/' + id).toPromise();
		const arr: Models.SavedLicenseSearch[] = result;
		return arr;
	}

	async getSavedSearches(forceReload = false) {
		if (forceReload || !this.savedSearches) {
			const result = await this.http.get<Models.SavedLicenseSearch[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/saved-searches').toPromise();
			this.savedSearches = result;
		} // if

		return this.savedSearches.slice();
	}

	// get a single saved search from the user's pool of saved searches
	async getSavedSearch(id: number, forceReload = false) {
		if (forceReload || !this.savedSearches) {
			const result = await this.http.get<Models.SavedLicenseSearch[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/saved-searches').toPromise();
			this.savedSearches = result;
		} // if

		const idx = MiscTools.findIndex(this.savedSearches, id);
		if (idx !== -1)
			return this.savedSearches[idx];
		else
			return null;
	}

	// get a single saved search from all users' saved searches
	async getSavedSearchFromAll(id: number) {
		const result = await this.http.get<Models.SavedLicenseSearch>(AppConstants.apiUrl
			+ AppConstants.apiUrls.licensing + '/saved-search/' + id).toPromise();
		const savedSearch: Models.SavedLicenseSearch = result;
		return savedSearch;
	}

	async linkKeyToOrganization(key: string, orgID: number, status: string, allowMove: boolean, id: number = 0) {
		const linkRequest = {
			id,
			key,
			org_id: orgID,
			status,
			allow_move: allowMove
		};

		try {
			const result = await this.http.post<boolean>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/link-organization', linkRequest).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to add one or more LPActivation
	async addActivations(baseActivation: Models.LPActivation, numKeys: number) {
		try {
			const result = await this.http.post<number[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + numKeys, baseActivation).toPromise();
			const ids: number[] = result;
			return ids;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a LPActivation
	async updateActivation(activation: Models.LPActivation) {
		try {
			const result = await this.http.put<Models.LPActivation>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + activation.id, activation).toPromise();
			const returnedActivation: Models.LPActivation = result;
			return returnedActivation;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a LPActivation
	async disableActivation(activation: Models.LPActivation) {
		try {
			const result = await this.http.put<Models.LPActivation>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + activation.id + '/disable', {}).toPromise();
			const returnedActivation: Models.LPActivation = result;
			return returnedActivation;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to update a LPActivation
	async enableActivation(activation: Models.LPActivation) {
		try {
			const result = await this.http.put<Models.LPActivation>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + activation.id + '/enable', {}).toPromise();
			const returnedActivation: Models.LPActivation = result;
			return returnedActivation;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// 
	async findPotentialOrganizations(id: number) {
		try {
			const result = await this.http.get<any[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + id + '/find-organization-matches/').toPromise();
			const matches = result;
			return matches;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// 
	async linkKeyToTemplate(id: number, templateID: number) {
		const linkRequest = {
			id,
			template_id: templateID
		};

		try {
			const result = await this.http.post<boolean>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/link-template', linkRequest).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to register new host IDs for offline licenses
	async offlineLicensesForHosts(id: number, hostids: string[], useAlternateServer: boolean, format: string) {
		try {
			const result = await this.http.put<any>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + id + '/offline', { hostids, useAlternateServer, format }).toPromise();
			const returnedValue = result;
			return returnedValue;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to convert a legacy license to a new offline key + license
	async convertLicenseToKey(id: number, hostID: string, templateID: number) {
		// try {
		// 	const result = await this.http.post<Models.LPActivation>(AppConstants.apiUrl
		// 		+ AppConstants.apiUrls.licensing + '/host/' + id + '/convert', { hostID, templateID }).toPromise();
		// 	const returnedValue: Models.LPActivation = result;
		// 	return returnedValue;
		// } catch (error) {
		// 	MiscTools.handleBackendError(error);
		// }
	}

	// call back-end to get billing codes used by a key
	async getActivationBillingCodes(id: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/billing-codes';
			const result = await this.http.get<Models.BillingCode[]>(url).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get billing codes used by a host id
	async getHostBillingCodes(hostid: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/billing-codes';
			const result = await this.http.get<Models.BillingCode[]>(url).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get all or a subset of all of the  billing codes
	async getBillingCodes(codes: string[] = []) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/billing-codes';
			if (codes && codes.length > 0)
				url += '?billing_codes=' + encodeURIComponent(codes.join(','))

			const result = await this.http.get<Models.BillingCode[]>(url).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async gatherKeyAndHostInfo(nameCombos: any[]) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/key-host-info';
			const result = await this.http.post<any>(url, nameCombos).toPromise();
			const ret: any = result;
			return ret;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async getAggregateMonthlyUsage(combos: any[], metricType: string, billingCode: string = '') {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/aggregate-monthly-usage';
			url += '?metricType=' + encodeURIComponent(metricType);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);

			const result = await this.http.post<Models.LPMeterReportByMonth[]>(url, combos).toPromise();
			const arr: Models.LPMeterReportByMonth[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async getAggregateDailyUsage(combos: any[], product: string, year: number, month: number, billingCode: string = '', brand: string = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/aggregate-daily-usage'
				+ '?product=' + encodeURIComponent(product)
				+ '&year=' + encodeURIComponent(year)
				+ '&month=' + encodeURIComponent(month);
			if (billingCode && billingCode !== '') url += '&bcode=' + encodeURIComponent(billingCode);
			if (brand != null) url += '&brand=' + encodeURIComponent(brand);

			const result = await this.http.post<Models.LPMeterReportByDay[]>(url, combos).toPromise();
			const arr: Models.LPMeterReportByDay[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get billing codes used by a host id
	async getAggregateBillingCodes(combos: any[]) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/aggregate-billing-codes';
			const result = await this.http.post<Models.BillingCode[]>(url, combos).toPromise();
			const arr: Models.BillingCode[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getTotalProtocolUsage(ids: number[], startDate: Date = null, endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/total-usage';
			if (startDate != null && endDate != null)
				url += '?startDate=' + TextTools.formatDateUTC(startDate)
					+ '&endDate=' + TextTools.formatDateUTC(endDate);

			const result = await this.http.post<any[]>(url, ids).toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// return get IDs for keys...
	async resolveKeys(keys: string[]) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/resolve-keys';
			const result = await this.http.post<any>(url, keys).toPromise();
			const obj: any = result;
			return obj;
		} catch (error) {
			MiscTools.handleBackendError(error);
			return null;
		}
	}

	// call back-end to get any hostids that haven't reported in over the last day, but did report in within
	// the last 3 days - either intentionlly shut down or unintentionally due to network (fw) changes/issues
	async getOfflineHosts(id: number) {
		try {
			const result = await this.http.get<string[]>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + id + '/offline-hosts').toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get array of keywriteaccess for a key
	async getKeyWriteAccess(keyId: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + keyId + '/write-access';
			const result = await this.http.get<Models.KeyWriteAccess[]>(url).toPromise();
			const arr: Models.KeyWriteAccess[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to set keywriteaccess for a key
	async setKeyWriteAccess(keyId: number, userIds: number[]) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + keyId + '/write-access';
			const result = await this.http.put(url, { user_ids: userIds }).toPromise();
			// const arr: Models.LPLicense[] = result;
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to set commercial info for a key
	async setKeyCommercial(keyId: number, commercialType: string, commercialInfo: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + keyId + '/commercial';
			const result = await this.http.put(url, { commercial_type: commercialType, commercial_info: commercialInfo }).toPromise();
			// const arr: Models.LPLicense[] = result;
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get array of keywriteaccess for a key
	async getHostLabels(hostid: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/labels';
			const result = await this.http.get<Models.UserHostId[]>(url).toPromise();
			const arr: Models.UserHostId[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get array of keywriteaccess for a key
	async getBillingCodeSummaryForKeyByHostId(keyId: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + keyId + '/billing-code-summary';
			const result = await this.http.get<any[]>(url).toPromise();
			const arr: any[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// call back-end to get protocol sets linked to a key
	async getActivationProtocolSets(keyId: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + keyId + '/protocol-sets';
			const result = await this.http.get<Models.ActivationProtocolSet[]>(url).toPromise();
			const arr: Models.ActivationProtocolSet[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to link a protocol set to a key
	async addActivationProtocolSet(keyId: number, setId: number, dataType: string, projected: number = 0) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + keyId + '/protocol-sets';
			const result = await this.http.post(url, { set_id: setId, data_type: dataType, projected: projected }).toPromise();
			// const arr: Models.LPLicense[] = result;
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to unlink a protocol set from a key
	async deleteActivationProtocolSet(keyId: number, setId: number, dataType: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + encodeURIComponent(keyId)
				+ '/protocol-sets/' + encodeURIComponent(setId) + '/' + encodeURIComponent(dataType);
			const result = await this.http.delete(url).toPromise();
			// const arr: Models.LPLicense[] = result;
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a key's pen tests
	async getActivationPenTests(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/pen-tests';
			const result = await this.http.get<Models.BroadcasterPenTest[]>(url).toPromise();
			const arr: Models.BroadcasterPenTest[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's pen tests
	async getHostIdPenTests(hostid: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/host/' + encodeURIComponent(hostid) + '/pen-tests';
			const result = await this.http.get<Models.BroadcasterPenTest[]>(url).toPromise();
			const arr: Models.BroadcasterPenTest[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async runPenTestOnIPs(ips: string[], username: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/run-pen-test';
			const result = await this.http.post(url, { ips, username }).toPromise();
			return;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// call back-end to get licenses for an IP
	async getIPLicenses(ip: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/ip/' + encodeURIComponent(ip) + '/licenses';
			const result = await this.http.get<Models.LPLicense[]>(url).toPromise();
			const arr: Models.LPLicense[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's monthly usage records
	async getIPActivations(ip: string) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/ip/' + encodeURIComponent(ip) + '/activations';
			const result = await this.http.get<Models.LPActivation[]>(url).toPromise();
			const arr: Models.LPActivation[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to get a host's pen tests
	async getIPPenTests(ip: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/ip/' + encodeURIComponent(ip) + '/pen-tests';
			const result = await this.http.get<Models.BroadcasterPenTest[]>(url).toPromise();
			const arr: Models.BroadcasterPenTest[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getNumLicenses(id: number) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/activation/' + id + '/num-licenses';
			const result = await this.http.get<any>(url).toPromise();
			let count = 0;
			if (result && result.count) count = result.count;
			return count;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// call back-end to link a legacy offline license to an offline key
	async linkLegacyLicenseToKey(id: number, key: string) {
		try {
			const result = await this.http.post<Models.LPActivation>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/host/' + id + '/link', { key }).toPromise();
			const returnedValue: Models.LPActivation = result;
			return returnedValue;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUserKeySnoozesForKey(id: number) {
		const result = await this.http.get<Models.UserLicenseKeySnooze[]>(AppConstants.apiUrl
			+ AppConstants.apiUrls.licensing + '/activation/' + id + '/user-key-snoozes').toPromise();
		const arr: Models.UserLicenseKeySnooze[] = result;
		return arr;
	}

	async marketplaceUpdate(activation: Models.LPActivation) {
		try {
			const result = await this.http.put<Models.LPActivation>(AppConstants.apiUrl
				+ AppConstants.apiUrls.licensing + '/activation/' + activation.id + '/marketplace', activation.marketplace).toPromise();
			const returnedActivation: Models.LPActivation = result;
			return returnedActivation;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


	// call back-end to get a host's daily usage records for a month
	async getInvalidBillingCodeCache(activationId: number, hostid: string, billingCode: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing + '/invalid-billing-code-cache'
				+ '?activationId=' + encodeURIComponent(activationId)
				+ '&hostid=' + encodeURIComponent(hostid)
				+ '&billingCode=' + encodeURIComponent(billingCode);

			const result = await this.http.get<Models.LPInvalidBillingCodeCache[]>(url).toPromise();
			const arr: Models.LPInvalidBillingCodeCache[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}


}
