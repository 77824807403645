import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import AccessControl from 'appshared/access-control';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { UserGroupsService } from '../user-groups.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { ReportsService } from '../../reports/reports.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-user-group',
	templateUrl: './user-group.component.html',
	styleUrls: ['./user-group.component.scss']
})
export class UserGroupComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	acl = AccessControl;
	popOverTools = PopOverTools;
	textTools = TextTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	group: Models.UserGroup;

	// other stuff
	private userSubscription: Subscription;
	members: Models.User[] = [];
	managers: Models.User[] = [];

	canEdit = false;
	canManageAccess = false;
	canDelete = false;

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	adminLogs: Models.AdminLog[] = [];

	assignedTagGroupings = {};
	showAccessTags = false;

	emails: string[] = [];

	groupTemplates: Models.KeyTemplate[] = [];
	showGroupTemplates = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userGroupsService: UserGroupsService,
		private usersService: UsersService,
		private authService: AuthService,
		private licensingAdminService: LicensingAdminService,
		private uiAlertsService: UiAlertsService,
		private reportsService: ReportsService,
		private adminLogsService: AdminLogsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.group = this.userGroupsService.getOne(this.id);
			if (!this.group || this.group == null || this.group.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.userSubscription = this.authService.user.subscribe(authUser => {
					this.canEdit = authUser
						&& (this.group.manager_ids.includes(authUser.id)
							|| ValidationTools.checkAccess(authUser, 'manage-user-groups'));
					this.canDelete = this.canEdit && authUser && ValidationTools.checkAccess(authUser, 'delete-user-groups');
					this.canManageAccess = authUser && ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);
					this.loadData();
				});

				this.userGroupsService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		// changes
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData(skipUsers = false) {
		this.loading = true;

		this.assignedTagGroupings = {};
		for (const accessControlTagGrouping of AccessControl.accessControlTagGroupings) {
			for (const tag of accessControlTagGrouping.tags) {
				if (this.group.accessTags.includes(tag)) {
					if (!this.assignedTagGroupings[accessControlTagGrouping.grouping])
						this.assignedTagGroupings[accessControlTagGrouping.grouping] = [];
					this.assignedTagGroupings[accessControlTagGrouping.grouping].push(tag);
				}
			}
		}

		const keyTemplates: Models.KeyTemplate[] = await this.licensingAdminService.getTemplates();
		this.groupTemplates = [];
		for (const keyTemplate of keyTemplates)
			if (keyTemplate.user_group_ids && keyTemplate.user_group_ids.includes(this.id))
				this.groupTemplates.push(keyTemplate);

		this.addedByUser = null;
		if (this.group.added_by && this.group.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.group.added_by);

		this.editedByUser = null;
		if (this.group.edited_by && this.group.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.group.edited_by);

		this.adminLogs = await this.adminLogsService.getLogs(['user-group'], this.id);

		if (this.group.user_ids && this.group.user_ids.length !== 0) {
			this.members = [];
			this.emails = [];
			for (const uid of this.group.user_ids) {
				const u = this.usersService.getOne(uid);
				if (u) {
					this.members.push(u);
					this.emails.push(u.email);
				}
			}
			this.members.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
			this.emails.sort();
		}

		if (this.group.manager_ids && this.group.manager_ids.length !== 0) {
			this.managers = [];
			for (const uid of this.group.manager_ids) {
				const u = this.usersService.getOne(uid);
				if (u) {
					this.managers.push(u);
				}
			}
			this.managers.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-user-group-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete User Group',
			'If you delete this User Group, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete User Group', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.userGroupsService.deleteOne(this.group.id);
		if (result) {
			this.uiAlertsService.addMsg('The group (' + this.group.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.usergroups]);
		} else {
			return false;
		}
	}

	async disable() {
		if (confirm('Are you sure you want to disable this Group?')) {
			const result = await this.userGroupsService.toggleEnabled(this.group.id);
			if (result) {
				this.group = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	async enable() {
		if (confirm('Are you sure you want to enable this Group?')) {
			const result = await this.userGroupsService.toggleEnabled(this.group.id);
			if (result) {
				this.group = result;
				this.loadData();
			} else {
				return false;
			}
		}
	}

	getGroupings(tagGroupings: any): string[] {
		return Object.keys(tagGroupings);
	}

	getUserIssues(user: Models.User) {
		return ValidationTools.getUserIssues(user, true).join(' ');
	}

	async openGroupReport() {
		let args = 'groupId=' + this.id;
		await this.reportsService.runReport('PortalUsersReport', args);
	}

	// ************************************************************************************************************************
	getColumnSubList(column: number, objects: any[]) {
		let sublist: any[] = [];

		const cuttoff: number = 5;

		// console.log('-------');
		// console.log('protocols=' + protocols.length);

		let col1: number = -1;
		let col2: number = -1;
		let col3: number = -1;

		if (objects.length <= cuttoff) { // one column
			col1 = objects.length;
		} else if (objects.length <= (cuttoff * 2)) { // two columns
			const per = Math.floor(objects.length / 2);
			// console.log('2 per=' + per);
			col1 = per + (objects.length % 2);
			col2 = col1 + per;
		} else { // three columns
			const per = Math.floor(objects.length / 3);
			col1 = per + (objects.length % 3);
			col2 = col1 + per;
			col3 = col2 + per;
		} // if
		// console.log('column=' + column + ' col1=' + col1 + ' col2=' + col2 + ' col3=' + col3);

		if (column === 1 && col1 > 0) {
			sublist = objects.slice(0, col1);
		} else if (column === 2 && col2 > 0) {
			sublist = objects.slice(col1, col2);
		} else if (column === 3 && col3 > 0) {
			sublist = objects.slice(col2, objects.length);
		} // if
		// console.log('sublist=' + sublist.length);


		return sublist;
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
