<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Users
			</li>
			<li class="breadcrumb-item">
				<a href="javascript:void(0)" (click)="onCancel()">Groups</a>
			</li>
			<li class="breadcrumb-item">
				{{ group.name }}
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Access
			</li>
		</ol>
	</nav>
</div>

<form *ngIf="theForm" [formGroup]="theForm">
	<div class="cp-general-width-limit">
		<div *ngIf="loading || saving" style="text-align: center;">
			<app-loading-spinner></app-loading-spinner>
		</div>

		<div *ngFor="let accessControlTagGrouping of acl.accessControlTagGroupings">
			{{ accessControlTagGrouping.grouping }}

			<div *ngFor="let tag of accessControlTagGrouping.tags" class="form-group form-check mb-2 mt-0 ms-4">
				<input type="checkbox" class="form-check-input" [id]="tag" [formControlName]="tag" />
				<label class="form-check-label ps-1 pe-1" [for]="tag" [ngClass]="{'cp-selected-primary': isChecked(tag)}">
					{{ acl.accessControlTags[tag] }}
				</label>
			</div>
		</div>
		<!-- 
		<div class="form-group mb-0" *ngIf="!saving">
			<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div> -->
	</div>

	<div class="cp-form-padding"></div>

	<div *ngIf="!loading && theForm" class="cp-form-buttons">
		<div class="cp-form-buttons-inner">
			<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
				<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
			</button>
			<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
				<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
			</button>
		</div>
	</div>
</form>