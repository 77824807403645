
class AccessControl {

	// these are all the tags implemented in the app...
	static readonly accessControlTags = {
		'access-all-builds-and-documents': 'Can download all Builds and Documents regardless of access',

		'manage-organizations-customer': 'Add/Edit "Customer" Organizations',
		'manage-organizations-partner': 'Add/Edit "Partner" Organizations',
		'manage-organizations-internal': 'Add/Edit "Internal" Organizations',

		'delete-organizations': 'Delete editable Organizations',

		'manage-basic-users-no-org': 'Add/Edit Basic Users not associated with an Organization',

		'manage-basic-users-customer': 'Add/Edit Basic Users from "Customer" Organizations',
		'manage-basic-users-partner': 'Add/Edit Basic Users from "Partner" Organizations',
		'manage-basic-users-internal': 'Add/Edit Basic Users from "Internal" Organizations',

		'delete-unused-basic-users': 'Delete editable Basic Users that have not been welcomed or logged in',
		'delete-any-user': 'Delete any editable User',

		'promote-basic-to-staff': 'Can promote Basic Users to Staff',

		'setup-user-with-sales-engineer-notifications': 'Can setup staff member to get SE/Tech-Rep key notifications',
		'setup-user-with-account-owner-notifications': 'Can setup staff member to get account owner key notifications',

		'manage-partnerships': 'Add/Edit/Delete Partner entries tied to Organizations',
		'manage-system-messages': 'Add/Edit/Delete Dashboard System Messages',
		'add-journals': 'Add journal entries to any object that support them',

		'manage-keys': 'Allowed to create/edit keys with assigned Templates',
		'change-key-template': 'Set/Change a Key\'s Template',
		'set-key-write-access': 'Can grant write access to a key to a staff member that doesn\'t have it',

		'manage-license-key-setup': 'Add/Edit/Delete License Key Products + Properties',
		'manage-key-templates': 'Add/Edit/Delete Key Templates',
		'manage-protocol-sets': 'Manage Protocol Sets',
		'link-protocol-sets-to-keys': 'Can link/unlink one or more protocol sets to keys',

		'manage-commercial': 'Allowed to set/update commercial type/info on license keys',
		'configure-key-for-marketplace': 'Can set/unset a license key to report to a marketplace',

		'run-stats-roll-up': 'Can kick off stats (meter/protocol) daily roll-up',
		'run-bx-pen-tests-task': 'Can kick off broadcaster pen tests',

		'manage-zen-master': 'Can Manage ZEN Master Sites (set/change organization, type, and/or marketplace info)',
		'admin-zen-master': 'Can Create/Launch New ZEN Master Sites and Edit Sites',
		'admin-zen-master-staff': 'Can Manage Zixi Staff Access to ZEN Master',
		'disable-zen-master': 'Can Disable/Shut Down Any ZEN Master Site',
		'enable-zen-master': 'Can Enable/Start Any ZEN Master Site',
		'delete-zen-master': 'Can Delete ZEN Master Sites',
		'disable-zen-master-as-delegate': 'Can Disable/Shut Down a ZEN Master Site if User is SE/Tech-Rep',
		'enable-zen-master-as-delegate': 'Can Enable/Start a ZEN Master Site if User is SE/Tech-Rep',

		'configure-zen-for-marketplace': 'Can set/unset a ZEN Master site to report to a marketplace',

		'create-special-zen-master-keys': 'Can kick off Automated Tasks that Create Special ZEN Master keys',
		'report-marketplace-usage': 'Can kick off Automated Tasks that Report Traffic For Marketplace Subscriptions',

		// 'send-zen-master-shutdown-notice': 'Can send account team ZEN Master shutdown notices',

		'manage-files': 'Upload/Edit Files',
		'manage-builds': 'Add/Edit Builds',
		'manage-documents': 'Add/Edit Documents',
		'delete-unused-files': 'Delete editable Files that have never been downloaded',
		'delete-files': 'Delete any editable File',
		'delete-builds': 'Delete editable Builds',
		'delete-documents': 'Delete editable Documents',
		'can-see-free-tokens': 'Can see free download tokens on files (if set)',

		'manage-user-groups': 'Add/Edit User Groups',
		'manage-organization-groups': 'Add/Edit Organization Groups',
		'manage-packages': 'Add/Edit Packages',
		'manage-products': 'Add/Edit Products related to Builds and Documents',
		'manage-platforms': 'Add/Edit Platforms',

		'delete-user-groups': 'Delete editable User Groups',
		'delete-organization-groups': 'Delete editable Organization Groups',
		'delete-packages': 'Delete editable Packages',
		'delete-products': 'Delete editable Products related to Builds and Documents',
		'delete-platforms': 'Delete editable Platforms',

		'view-billing-codes': 'Can view billing codes (with authorization code) for an Organization',
		'manage-billing-codes': 'Can manage billing codes for editable Organizations',
		'validate-billing-codes': 'Can validate billing codes via the API',

		'send-build-notifications': 'Can send build e-mail notifications',
		'send-document-notifications': 'Can send document e-mail notifications',
		'send-product-notifications': 'Can send product e-mail notifications',
		'send-general-notifications': 'Can send general e-mail notifications',
		'run-automated-notifications': 'Can kick off automated e-mail notifications',
		'manage-email-queue': 'Can manage the e-mail queue (flush queue, update status, direct add)',

		'create-staff-send-bundle': 'Can create bundles to send files to other users as themself',
		'create-group-send-bundle': 'Can create bundles to send files to other users from one of their groups',
		'create-staff-receive-bundle': 'Can create bundles to have files sent to them',
		'create-group-receive-bundle': 'Can create bundles to have files sent to one of their groups',
		'run-automated-bundle-cleanup': 'Can kick off automated file/bundle clean up routines',

		'run-user-clean-up': 'Can kick off automated unused user clean up routine',
		'run-organization-clean-up': 'Can kick off automated unused organization clean up routine',
		'run-email-clean-up': 'Can kick off automated unused e-mail queue clean up routine',

		'run-usage-data-clean-up': 'Can kick off automated clean up of meter and protocol usage data',
		'run-background-task-clean-up': 'Can kick off automated clean up of background task records',

		'run-zen-pull-targets-report': 'Can run the Music Choice Specific Zen Master Pull Targets Report',

		'manage-quizzes': 'Can manage any Quiz/Poll',
		'add-quizzes': 'Can start and manage a Quiz/Poll',

		'run-dashboard-refresh': 'Can kick off automated refresh of dashboard top usage data',
		'run-sanity-check': 'Can kick off system wide sanity check',

		'compare-meters-to-protocol': 'Can compare meter usage to equivalent protocol usage',
		'expose-brands': 'Let user see specific brands tagged in a key or host ID\'s usage and let them filter usage to a specific brand'

	};
	// 'experimental-key-edit': 'Add/Edit Keys (Experimental)',

	static readonly accessControlTagGroupings = [
		{
			grouping: 'Downloads',
			tags: ['access-all-builds-and-documents']
		}, {
			grouping: 'Organizations',
			tags: [
				'manage-organizations-customer',
				'manage-organizations-partner',
				'manage-organizations-internal',
				'delete-organizations']
		}, {
			grouping: 'Users',
			tags: [
				'manage-basic-users-no-org',
				'manage-basic-users-customer',
				'manage-basic-users-partner',
				'manage-basic-users-internal',
				'delete-unused-basic-users',
				'delete-any-user',
				'promote-basic-to-staff',
				'setup-user-with-sales-engineer-notifications',
				'setup-user-with-account-owner-notifications']
		}, {
			grouping: 'License Keys',
			tags: [
				'manage-keys',
				'change-key-template',
				'set-key-write-access',
				'manage-key-templates',
				'manage-license-key-setup',
				'manage-protocol-sets',
				'link-protocol-sets-to-keys',
				'manage-commercial',
				'configure-key-for-marketplace',
				'compare-meters-to-protocol',
				'expose-brands'
			]
		}, {
			grouping: 'ZEN Master',
			tags: [
				'manage-zen-master',

				'admin-zen-master',
				'admin-zen-master-staff',
				'disable-zen-master',
				'enable-zen-master',
				'delete-zen-master',

				'disable-zen-master-as-delegate',
				'enable-zen-master-as-delegate',

				'configure-zen-for-marketplace',

				'run-zen-pull-targets-report',
			]
		}, {
			grouping: 'Misc.',
			tags: [
				'manage-partnerships',
				'manage-system-messages',
				'add-journals',
				'manage-quizzes',
				'add-quizzes'
			]
		}, {
			grouping: 'Billing Codes',
			tags: [
				'view-billing-codes',
				'manage-billing-codes',
				'validate-billing-codes'
			]
		}, {
			grouping: 'Files, Builds and Documents',
			tags: [
				'manage-files',
				'manage-builds',
				'manage-documents',
				'delete-files',
				'delete-unused-files',
				'delete-builds',
				'delete-documents',
				'can-see-free-tokens',
			]
		}, {
			grouping: 'Notifications',
			tags: [
				'send-build-notifications',
				'send-document-notifications',
				'send-general-notifications',
				'send-product-notifications',
			]

		}, {
			grouping: 'File Sharing Bundles',
			tags: [
				'create-staff-send-bundle',
				'create-group-send-bundle',
				'create-staff-receive-bundle',
				'create-group-receive-bundle',
			]
		}, {
			grouping: 'Admin Sections',
			tags: [
				'manage-user-groups',
				'manage-organization-groups',
				'manage-packages',
				'manage-products',
				'manage-platforms',
				'delete-user-groups',
				'delete-organization-groups',
				'delete-packages',
				'delete-products',
				'delete-platforms',
			]
		}, {
			grouping: 'Automated Tasks',
			tags: [
				'run-organization-clean-up',
				'run-user-clean-up',
				'run-stats-roll-up',
				'run-usage-data-clean-up',
				'run-bx-pen-tests-task',
				'create-special-zen-master-keys',
				'report-marketplace-usage',
				'run-background-task-clean-up',
				'run-automated-notifications',
				'manage-email-queue',
				'run-email-clean-up',
				'run-automated-bundle-cleanup',
				'run-dashboard-refresh',
				'run-sanity-check'
			]
		}
	];

	// users with these roles inherit these AC Tags
	static readonly roleAccessControlTags = {
		basic: [],
		staff: [],
		admin: ['*']
	};


}

export default AccessControl;
